import { ChangeEvent, FormEvent, FunctionComponent, useState, PropsWithChildren } from 'react';
import { useField, Form } from 'formik';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { useExtendedIntl } from 'hooks/useExtendedIntl';
import { useUserTracking } from 'userTracking/useUserTracking';
import { CustomNumberFormat } from 'shared/CustomNumberFormat';
import { ProgressButton } from 'shared/ProgressButtons/ProgressButton';
import {
  BillingProfileTopupAmountLimitsNode,
  BillingProfileAutoTopupThresholdAmountLimitsNode,
} from 'typeDeclarations/graphql/nodes';
import { useDefaultOnError } from 'hooks/useDefaultOnError';
import { MenuItem, TextField, Typography, Stack } from '@mui/material';
import { useGetAmountWithVat } from 'shared/Wallet/utils';
import { ErrorMessage } from 'shared/ErrorMessage';
import { LoadingBlock } from 'shared/LoadingBlock';
import { TopupInputBillingProfileNode, TopupInputCurrencies } from './fragments';

interface AutoTopupAmountLimitsVariables {
  currency: string;
}

interface AutoTopupAmountLimitsData {
  billingProfileAutoTopupAmountLimits: Pick<BillingProfileTopupAmountLimitsNode, 'minimumAmount'>;
  billingProfileAutoTopupThresholdAmountLimits: Pick<
    BillingProfileAutoTopupThresholdAmountLimitsNode,
    'suggestedAmounts'
  >;
}

const AUTO_TOPUP_AMOUNT_LIMITS = gql`
  query autoTopupAmountLimits($currency: String) {
    billingProfileAutoTopupAmountLimits(currency: $currency) {
      minimumAmount
    }
    billingProfileAutoTopupThresholdAmountLimits(currency: $currency) {
      suggestedAmounts
    }
  }
`;

interface UpdateBillingProfileCurrencyVariables {
  input: {
    currency: string;
    autoTopupAmount?: string;
    autoTopupThresholdAmount: string | null;
  };
}

const UPDATE_BILLING_PROFILE_CURRENCY = gql`
  mutation updateBillingProfileCurrency($input: UpdateBillingProfileInput!) {
    updateBillingProfile(input: $input) {
      billingProfile {
        id
        modified
        currency
        autoTopupThresholdAmount
        autoTopupThresholdAmountLimits {
          suggestedAmounts
        }
        topupAmountLimits {
          minimumAmount
          maximumAmount
        }
      }
    }
  }
`;

interface WalletManualTopUpFormProps {
  loading: boolean;
  disabled: boolean;
  allCurrencies: TopupInputCurrencies;
  billingProfile: TopupInputBillingProfileNode;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
}

export const WalletManualTopUpForm: FunctionComponent<PropsWithChildren<WalletManualTopUpFormProps>> = ({
  loading,
  disabled,
  handleSubmit,
  allCurrencies,
  billingProfile,
}) => {
  const onError = useDefaultOnError();
  const { captureEvent } = useUserTracking();
  const { formatMessage, formatNumber, formatNullableNumber } = useExtendedIntl();

  const [amount, setAmount] = useState<string | undefined>(undefined);
  const [currencyField, , { setValue }] = useField<string>('currency');

  const { balance, currency, autoTopupAmount, isCurrencyMutable, autoTopupThresholdAmount } = billingProfile;

  const [updateBillingProfileCurrency, { loading: mutationLoading }] = useMutation<
    unknown,
    UpdateBillingProfileCurrencyVariables
  >(UPDATE_BILLING_PROFILE_CURRENCY, { onError: (err) => onError(err) });

  const [autoTopupAmountLimits, { loading: lazyQueryLoading }] = useLazyQuery<
    AutoTopupAmountLimitsData,
    AutoTopupAmountLimitsVariables
  >(AUTO_TOPUP_AMOUNT_LIMITS, {
    onError,
    onCompleted: (data) => {
      const { minimumAmount: minimumAutoTopupAmount } = data.billingProfileAutoTopupAmountLimits;
      const { suggestedAmounts } = data.billingProfileAutoTopupThresholdAmountLimits;
      // We set `autoTopupThresholdAmount` here because it's set to the minimum if it's `null`
      const mutationVariables: UpdateBillingProfileCurrencyVariables = {
        input: {
          currency: currencyField.value,
          autoTopupThresholdAmount,
        },
      };

      // If the minimum auto topup amount is below the currently set amount, then update this
      if (Number(minimumAutoTopupAmount) > Number(autoTopupAmount)) {
        mutationVariables.input.autoTopupAmount = minimumAutoTopupAmount;
      }

      // If the suggested thresholds do not include the current threshold amount, set this to the
      // lowest suggested threshold
      if (!suggestedAmounts.includes(autoTopupThresholdAmount ?? '0')) {
        [mutationVariables.input.autoTopupThresholdAmount] = suggestedAmounts;
      }

      updateBillingProfileCurrency({ variables: mutationVariables });
    },
  });

  const {
    data,
    error,
    loading: useGetAmountWithVatLoading,
  } = useGetAmountWithVat({
    skip: !amount,
    fetchPolicy: 'network-only',
    variables: {
      amount,
    },
  });

  let content = (
    <Typography variant="h6" fontWeight="bold">
      {formatNumber(0, { style: 'currency', currency })}
    </Typography>
  );

  if (useGetAmountWithVatLoading) {
    content = (
      <LoadingBlock
        sx={{
          width: '1rem',
          height: '1rem',

          '& > span': {
            // This is required to override the default MUI spinner size
            width: '100% !important',
            height: '100% !important',
          },
        }}
      />
    );
  }

  if (error) content = <ErrorMessage />;

  if (data) {
    const { amountWithVat } = data.getVatAmount;
    content = (
      <Typography variant="h6" fontWeight="bold">
        {formatNumber(Number(amountWithVat), { style: 'currency', currency })}
      </Typography>
    );
  }

  const [topupAmountField, topupAmountMeta] = useField<string>({ name: 'topupAmount' });

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedCurrency = event.target.value;
    autoTopupAmountLimits({ variables: { currency: selectedCurrency } });
    setValue(selectedCurrency);
  };

  const formDisabled = disabled || mutationLoading || lazyQueryLoading;

  return (
    <Stack rowGap={2}>
      <Form onSubmit={handleSubmit}>
        <Stack rowGap={2}>
          <Stack direction="row" columnGap={2}>
            <TextField
              {...currencyField}
              select
              variant="outlined"
              sx={{ minWidth: 100 }}
              onChange={onChangeHandler}
              disabled={!isCurrencyMutable}
              label={formatMessage({ id: 'shared.currency' })}
            >
              {allCurrencies.edges.map(({ node: { _id, selectable } }) => (
                <MenuItem key={_id} value={_id} disabled={!selectable}>
                  {_id}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              {...topupAmountField}
              fullWidth
              variant="outlined"
              disabled={formDisabled}
              error={Boolean(topupAmountMeta.error)}
              helperText={topupAmountMeta.error ?? ''}
              InputProps={{ inputComponent: CustomNumberFormat }}
              label={formatMessage({ id: 'wallet-content.top-up-amount-input-label' })}
              onBlur={(event) => {
                topupAmountField.onBlur(event);
                if (!topupAmountMeta.error) {
                  setAmount(event.target.value);
                }
              }}
            />
            <Stack gap={1} width={250} justifyContent="center" alignItems="center">
              <Typography variant="body2">{formatMessage({ id: 'wallet.amount-charged' })}</Typography>
              {content}
            </Stack>
          </Stack>
          <Typography variant="subtitle2">
            {formatMessage(
              { id: 'topup-input.balance' },
              { balance: formatNullableNumber(Number(balance), { style: 'currency', currency }) },
            )}
          </Typography>
          <Stack direction="row" justifyContent="flex-end">
            <ProgressButton
              type="submit"
              loading={loading}
              variant="contained"
              onClick={() => captureEvent({ eventName: 'addMoney' })}
              disabled={Boolean(topupAmountMeta.error) || formDisabled}
            >
              {formatMessage({ id: 'wallet-manual-top-up.complete-top-up-button' })}
            </ProgressButton>
          </Stack>
        </Stack>
      </Form>
    </Stack>
  );
};
