import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { FunctionComponent, lazy, Suspense } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { useUtilsBoot } from 'hooks/useUtilsBoot';
import { APIPermission } from 'typeDeclarations/enums';
import { useAppSessionLocale } from 'hooks/useAppSessionLocale';
import { ReferralAnnouncement } from 'shared/ReferralAnnouncement';
import { PermissionsViewDecider } from 'shared/PermissionsViewDecider';
import { useRedirectUserThemePartner } from 'hooks/useRedirectUserThemePartner';
import { useShouldShowFirstBusinessPrompt } from 'hooks/useShouldShowFirstBusinessPrompt';
import { useShouldShowACS } from 'components/Campaign/useShouldShowACS';
import { WalletDialog } from 'shared/Wallet/WalletDialog';
import { CampaignProvider } from 'pages/Campaign/CampaignContext/CampaignProvider';
import { AppLayout } from 'components/Layouts/AppLayout';

const LazyExpiredLinkPage = lazy(() =>
  import('pages/ExpiredLink').then(({ ExpiredLink }) => ({ default: ExpiredLink })),
);

const LazyFirstBusinessPage = lazy(() =>
  import('pages/FirstBusiness/FirstBusinessPage/FirstBusinessPage').then(({ FirstBusinessPage }) => ({
    default: FirstBusinessPage,
  })),
);

const LazyAllCampaignsPage = lazy(() =>
  import('pages/AdvertisersAndCampaigns/Campaigns/CampaignsPage/CampaignsPage').then(({ CampaignsPage }) => ({
    default: CampaignsPage,
  })),
);

const LazyAllAdvertisersPage = lazy(() =>
  import('pages/AdvertisersAndCampaigns/Advertisers/AdvertisersPage/AdvertisersPage').then(({ AdvertisersPage }) => ({
    default: AdvertisersPage,
  })),
);

const LazyAccountSettingsPage = lazy(() =>
  import('pages/AccountSettings/AccountSettings').then(({ AccountSettingsPage }) => ({ default: AccountSettingsPage })),
);

const LazyTeamManagementPage = lazy(() =>
  import('pages/TeamManagement/TeamManagement').then(({ TeamManagementPage }) => ({ default: TeamManagementPage })),
);

const LazyTeamDashboard = lazy(() =>
  import('pages/TeamDashboard/TeamDashboard').then(({ TeamDashboard }) => ({ default: TeamDashboard })),
);

const LazyCampaignPage = lazy(() =>
  import('pages/Campaign/Campaign').then(({ CampaignPage }) => ({ default: CampaignPage })),
);

const LazyBudgetEstimatorPage = lazy(() =>
  import('pages/BudgetEstimator/BudgetEstimator').then(({ BudgetEstimator }) => ({ default: BudgetEstimator })),
);

const LazyCampaignDashboardReport = lazy(() =>
  import('components/print/CampaignDashboard/CampaignDashboardReport').then(({ CampaignDashboardReport }) => ({
    default: CampaignDashboardReport,
  })),
);

const LazyLegacyCampaignSummaryPrint = lazy(() =>
  import('components/print/LegacyCampaignSummary/CampaignSummaryPrint').then(({ CampaignSummaryPrint }) => ({
    default: CampaignSummaryPrint,
  })),
);

const LazyCampaignSummaryPrint = lazy(() =>
  import('components/print/CampaignSummary/CampaignSummaryPrint').then(({ CampaignSummaryPrint }) => ({
    default: CampaignSummaryPrint,
  })),
);

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const Loading: FunctionComponent = () => (
  <StyledDiv>
    <CircularProgress size="5rem" thickness={2.5} />
  </StyledDiv>
);

const CampaignSummaryPrint: FunctionComponent = () => {
  const { campaignPrettyId } = useParams();

  const shouldShowACS = useShouldShowACS({ campaignPrettyId });

  return (
    <CampaignProvider>
      {shouldShowACS ? <LazyLegacyCampaignSummaryPrint /> : <LazyCampaignSummaryPrint />}
    </CampaignProvider>
  );
};

export const ProtectedRoutes: FunctionComponent = () => {
  // TODO(react-router): Should these initializations and checks be moved from this component to another place???
  useUtilsBoot();

  const { loading: useRedirectUserThemePartnerLoading, error: useRedirectUserThemePartnerError } =
    useRedirectUserThemePartner();

  const { loading: shouldShowFirstBusinessLoading, error: shouldShowFirstBusinessError } =
    useShouldShowFirstBusinessPrompt();

  const { loading: gettingAppLocale } = useAppSessionLocale();

  if (useRedirectUserThemePartnerLoading || shouldShowFirstBusinessLoading || gettingAppLocale) return <Loading />;

  if (useRedirectUserThemePartnerError) throw useRedirectUserThemePartnerError;
  if (shouldShowFirstBusinessError) throw shouldShowFirstBusinessError;

  // TODO(react-router): Some of these routes should have permissions set.
  //
  // If the user doesn't have a business:
  // - They can only access /first-business.
  //
  // If the user has a business:
  // - They can access *every* page, except /first-business.
  //
  // Exception:
  // - /team/dashboard/* can only be accessible if the user has a minimum of 2 running campaigns. This can be backed up
  // by looking at the code found on `CampaignsPage.tsx`.
  //
  // Also, the /first-business assertion is already done via a custom hook, but we could move it here now.
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/print/campaign-dashboard/:campaignPrettyId"
            element={
              <CampaignProvider>
                <LazyCampaignDashboardReport />
              </CampaignProvider>
            }
          />
          <Route path="/print/campaign-summary/:campaignPrettyId" element={<CampaignSummaryPrint />} />
          <Route element={<AppLayout />}>
            <Route path="/first-business" element={<LazyFirstBusinessPage />} />
            <Route path="/link-has-expired" element={<LazyExpiredLinkPage />} />
            <Route path="/businesses" element={<LazyAllAdvertisersPage />} />
            <Route path="/campaigns" element={<LazyAllCampaignsPage />} />
            <Route path="/campaign/:campaignPrettyId/*" element={<LazyCampaignPage />} />
            <Route path="/team">
              <Route index element={<Navigate to="/team/dashboard/overview" replace />} />
              <Route path="billing/*" element={<LazyTeamManagementPage />} />
              <Route path="dashboard/*" element={<LazyTeamDashboard />} />
            </Route>
            <Route path="/account-settings/*" element={<LazyAccountSettingsPage />} />
            <Route
              path="/budget-estimator/*"
              element={
                <PermissionsViewDecider
                  fallback={<Navigate to="/not-found" replace />}
                  requiredPermissions={[APIPermission.EstimatedCostPerGoalWithUrl]}
                >
                  <LazyBudgetEstimatorPage />
                </PermissionsViewDecider>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={'/not-found'} replace />} />
        </Routes>
      </Suspense>
      <ReferralAnnouncement />
      <WalletDialog />
    </>
  );
};
