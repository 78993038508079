import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from 'components/App/AuthProvider';
import { useSetupApp } from 'components/Layouts/hooks/useSetupApp';

export const ProtectedLayout = () => {
  useSetupApp();

  const location = useLocation();
  const { session } = useAuth();

  // The user is not logged in
  if (!session) {
    return <Navigate to="/auth/login" replace state={{ from: location }} />;
  }

  // Redirect DDT users to /direct
  if (!session.user.isStaff && session.user.ddtId) {
    return <Navigate to="/direct" replace />;
  }

  return <Outlet />;
};
