import { gql } from '@apollo/client';
import {
  AdvertisingPlatformNode,
  AllKeywordMatchTypesNode,
  AllKInAdPoolsStatesInterface,
  AreaOfInterestCategoryNode,
  CampaignGoalNode,
  ChildVerticalNode,
  CountryNode,
  CurrencyNode,
  DeviceTypeNode,
  DisplayPlatformNode,
  FrontendSettingNode,
  GenderNode,
  IssueNode,
  LanguageNode,
  LocaleNode,
  SocialProviderNode,
  SpecialAdCategoryNode,
  TimezoneNode,
  UserThemeNode,
  VerticalNode,
} from 'typeDeclarations/graphql/nodes';
import { NonPaginatedConnection, PageInfo } from 'typeDeclarations/graphql/base-types';

export type PageInfoFragmentData = PageInfo;

export const PAGE_INFO_FRAGMENT = gql`
  fragment pageInfoFragment on PageInfo {
    endCursor
    hasNextPage
    modified
  }
`;

export interface AllLanguagesFragmentData {
  allLanguages: NonPaginatedConnection<
    Pick<LanguageNode, '__typename' | 'id' | '_id' | 'selectable' | 'isSupportedByWizard'>
  >;
}

export const ALL_LANGUAGES_FRAGMENT = gql`
  fragment allLanguagesFragment on Query {
    allLanguages {
      edges {
        node {
          id
          _id
          selectable
          isSupportedByWizard
        }
      }
    }
  }
`;

export interface AllUserThemesFragmentData {
  allUserThemes: NonPaginatedConnection<Pick<UserThemeNode, '__typename' | 'id' | '_id' | 'domain'>>;
}

export const ALL_USER_THEMES_FRAGMENT = gql`
  fragment allUserThemesFragment on Query {
    allUserThemes {
      edges {
        node {
          id
          _id
          domain
        }
      }
    }
  }
`;

export interface AllVerticalsFragmentData {
  allVerticals: NonPaginatedConnection<
    Pick<VerticalNode, '__typename' | 'id' | '_id' | 'selectable' | 'childVerticals'>
  >;
}

export const ALL_VERTICALS_FRAGMENT = gql`
  fragment allVerticalsFragment on Query {
    allVerticals @connection(key: "allVerticals") {
      edges {
        node {
          id
          _id
          selectable
          childVerticals
        }
      }
    }
  }
`;

export interface AllChildVerticalsFragmentData {
  allChildVerticals: NonPaginatedConnection<
    Pick<ChildVerticalNode, '__typename' | 'id' | '_id' | 'selectable' | 'parentVerticals'>
  >;
}

export const ALL_CHILD_VERTICALS_FRAGMENT = gql`
  fragment allChildVerticalsFragment on Query {
    allChildVerticals @connection(key: "allChildVerticals") {
      edges {
        node {
          id
          _id
          selectable
          parentVerticals
        }
      }
    }
  }
`;

export interface AllCountriesFragmentData {
  allCountries: NonPaginatedConnection<Pick<CountryNode, '__typename' | 'id' | '_id' | 'selectable' | 'geocoderId'>>;
}

export const ALL_COUNTRIES_FRAGMENT = gql`
  fragment allCountriesFragment on Query {
    allCountries {
      edges {
        node {
          id
          _id
          selectable
          geocoderId
        }
      }
    }
  }
`;

export interface AllSpecialAdCategoriesFragmentData {
  allSpecialAdCategories: NonPaginatedConnection<
    Pick<SpecialAdCategoryNode, 'id' | '_id' | 'selectable' | '__typename'>
  >;
}

export const ALL_SPECIAL_AD_CATEGORIES_FRAGMENT = gql`
  fragment allSpecialAdCategoriesFragment on Query {
    allSpecialAdCategories {
      edges {
        node {
          id
          _id
          selectable
        }
      }
    }
  }
`;

export interface AllCurrenciesFragmentData {
  allCurrencies: NonPaginatedConnection<
    Pick<CurrencyNode, 'id' | '_id' | 'symbol' | '__typename' | 'selectable' | 'decimalPlaces'>
  >;
}

export const ALL_CURRENCIES_FRAGMENT = gql`
  fragment allCurrenciesFragment on Query {
    allCurrencies {
      edges {
        node {
          id
          _id
          symbol
          selectable
          decimalPlaces
        }
      }
    }
  }
`;

export interface AllLocalesFragmentData {
  allLocales: NonPaginatedConnection<Pick<LocaleNode, '__typename' | 'id' | '_id' | 'selectable' | 'language'>>;
}

export const ALL_LOCALES_FRAGMENT = gql`
  fragment allLocalesFragment on Query {
    allLocales {
      edges {
        node {
          id
          _id
          selectable
          language
        }
      }
    }
  }
`;

export interface AllPerformanceCampaignGoalsFragmentData {
  allCampaignGoals: NonPaginatedConnection<
    Pick<CampaignGoalNode, '__typename' | 'id' | '_id' | 'selectable' | 'requiresSnippet'>
  >;
}

export const ALL_CAMPAIGN_GOALS_FRAGMENT = gql`
  fragment allCampaignGoalsFragment on Query {
    allCampaignGoals(supportsPerformanceCampaigns: true) {
      edges {
        node {
          id
          _id
          selectable
          requiresSnippet
        }
      }
    }
  }
`;

export interface AllAdvertisingPlatformsFragmentData {
  allAdvertisingPlatforms: NonPaginatedConnection<
    Pick<AdvertisingPlatformNode, 'id' | '_id' | 'selectable' | '__typename'>
  >;
}

export const ALL_ADVERTISING_PLATFORMS_FRAGMENT = gql`
  fragment allAdvertisingPlatformsFragment on Query {
    allAdvertisingPlatforms @connection(key: "allAdvertisingPlatforms") {
      edges {
        node {
          id
          _id
          selectable
        }
      }
    }
  }
`;

export type DraftIssueFragmentData = IssueNode;

export type NullableDraftIssueFragmentData = Nullable<DraftIssueFragmentData>;

export const DRAFT_ISSUE_FRAGMENT = gql`
  fragment draftIssueFragment on IssueNode {
    exception
    fieldNames
    message
    preventPublishIfIsPerformance
    extensions {
      data
      error_code
      is_user_error
    }
  }
`;

export type AllSocialProvidersData = {
  allSocialProviders: SocialProviderNode[];
};

export const ALL_SOCIAL_PROVIDERS_FRAGMENT = gql`
  fragment allSocialProvidersFragment on Query {
    allSocialProviders {
      _id
      name
      loginEndpoint
      connectEndpoint
    }
  }
`;

export interface AllGendersFragmentData {
  allGenders: NonPaginatedConnection<Pick<GenderNode, '__typename' | 'id' | '_id' | 'selectable'>>;
}

export const ALL_GENDERS_FRAGMENT = gql`
  fragment allGendersFragment on Query {
    allGenders @connection(key: "allGenders") {
      edges {
        node {
          id
          _id
          selectable
        }
      }
    }
  }
`;

export interface AllDeviceTypesFragmentData {
  allDeviceTypes: NonPaginatedConnection<Pick<DeviceTypeNode, '__typename' | 'id' | '_id' | 'selectable'>>;
}

export const ALL_DEVICE_TYPES_FRAGMENT = gql`
  fragment allDeviceTypesFragment on Query {
    allDeviceTypes @connection(key: "allDeviceTypes") {
      edges {
        node {
          id
          _id
          selectable
        }
      }
    }
  }
`;

export interface AllAreaOfInterestCategoriesFragmentData {
  allAreaOfInterestCategories: NonPaginatedConnection<
    Pick<AreaOfInterestCategoryNode, '__typename' | 'id' | '_id' | 'selectable'>
  >;
}

export const ALL_AREA_OF_INTEREST_CATEGORIES_FRAGMENT = gql`
  fragment allAreaOfInterestCategoriesFragment on Query {
    allAreaOfInterestCategories @connection(key: "allAreaOfInterestCategories") {
      edges {
        node {
          id
          _id
          selectable
        }
      }
    }
  }
`;

export const FRONTEND_SETTING_CONNECTION_FRAGMENT = gql`
  fragment frontendSettingConnectionFragment on FrontendSettingNodeConnection {
    edges {
      node {
        id
        name
        value
        modified
      }
    }
    pageInfo {
      modified
      hasNextPage
    }
  }
`;

export type FrontendSettingConnectionFragmentData = NonPaginatedConnection<
  Pick<FrontendSettingNode, 'id' | 'name' | 'value' | 'modified' | '__typename'>
> & {
  pageInfo: Pick<PageInfoFragmentData, 'hasNextPage' | 'modified'>;
};

export interface AllDisplayPlatformsFragmentData {
  allDisplayPlatforms: NonPaginatedConnection<Pick<DisplayPlatformNode, 'id' | '_id' | 'selectable' | '__typename'>>;
}

export const ALL_DISPLAY_PLATFORMS_FRAGMENT = gql`
  fragment allDisplayPlatformsFragment on Query {
    allDisplayPlatforms: allDisplayPlatform {
      edges {
        node {
          id
          _id
          selectable
        }
      }
    }
  }
`;

export interface AllKeywordMatchTypesFragmentData {
  allKeywordMatchTypes: NonPaginatedConnection<
    Pick<AllKeywordMatchTypesNode, 'id' | '_id' | 'selectable' | '__typename'>
  >;
}

export const ALL_KEYWORD_MATCH_TYPES_FRAGMENT = gql`
  fragment allKeywordMatchTypesFragment on Query {
    allKeywordMatchTypes {
      edges {
        node {
          id
          _id
          selectable
        }
      }
    }
  }
`;

export interface AllKeywordInAdPoolsStatesFragmentData {
  allKeywordInAdPoolStates: Array<AllKInAdPoolsStatesInterface>;
}

export const ALL_KEYWORD_IN_ADPOOLS_STATES_FRAGMENT = gql`
  fragment allKeywordInAdPoolsStatesFragment on Query {
    allKeywordInAdPoolStates {
      id
      _id
      name
    }
  }
`;

export interface AllTimezonesFragmentData {
  allTimezones: NonPaginatedConnection<TimezoneNode>;
}

export const ALL_TIMEZONES_FRAGMENT = gql`
  fragment allTimezonesFragment on Query {
    allTimezones {
      edges {
        node {
          id
          _id
          __typename
          name
          utcOffset
          summerUtcOffset
          abbreviation
          summerAbbreviation
          selectable
        }
      }
    }
  }
`;
